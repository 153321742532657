import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: '',
    list: null,
    details: null,
};

export const getters = {
    loadStatus: state => state.status,
    details: state => state.details,
    list: state => state.list
};

export const mutations = {
    /** LOAD */
    [types.LOAD_DETAILS_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_DETAILS_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.details = data
    },
    [types.LOAD_DETAILS_ERROR](state) {
        state.status = 'error'
    },
    /** LIST */
    [types.LIST_DETAILS_LOADING](state) {
        state.status = 'loading'
    },
    [types.LIST_DETAILS_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.list = data
    },
    [types.LIST_DETAILS_ERROR](state) {
        state.status = 'error'
    }
};

export const actions = {
    load({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_DETAILS_LOADING);
            http.get('details').then(({data}) => {
                commit(types.LOAD_DETAILS_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_DETAILS_ERROR)
                reject(response.data)
            })
        })
    },
    list({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LIST_DETAILS_LOADING);
            http.get('details/grouped-key').then(({data}) => {
                commit(types.LIST_DETAILS_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LIST_DETAILS_ERROR)
                reject(response.data)
            })
        })
    }
};
