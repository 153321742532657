export default {
    api: {
        "Exception": "Exception",
        "Request is failed!": "Request is failed!",
        "Authentication is required!": "Authentication is required!",
        "Resource not found!": "Resource not found!",
        "Unauthorized": "Unauthorized",
        "Invalid credentials": "Invalid credentials",
        "Successfully updated.": "Successfully updated.",
        "Successfully created.": "Successfully created.",
        "Successfully deleted!": "Successfully deleted!",
        "The ministry field is required.": "The @:(label.department) field is required.",
        "The role field is required.": "The @:(label.role) field is required.",
        "The sex field is required.": "The @:(label.sex) field is required.",
        "The name field is required.": "The @:(label.name) field is required.",
        "The email field is required.": "The @:(label.email) field is required.",
        "The address field is required.": "The @:(label.address) field is required.",
        "The baptized field is required.": "The @:(label.baptized) field is required.",
        "The birthday field is required.": "The @:(label.birthday) field is required.",
        "The cep field is required.": "The @:(label.cep) field is required.",
        "The city field is required.": "The @:(label.city) field is required.",
        "The image field is required.": "The @:(label.image) field is required.",
        "The married field is required.": "The @:(label.married) field is required.",
        "The mobile field is required.": "The @:(label.mobile) field is required.",
        "The phone field is required.": "The @:(label.phone) field is required.",
        "The wedding_date field is required.": "The @:(label.wedding_date) field is required.",
        "The email has already been taken.": "The @:(label.email) has already been taken.",
        "The wedding_date is not a valid date.": "The @:(label.wedding_date) is not a valid date.",
        "The birthday is not a valid date.": "The @:(label.birthday) is not a valid date.",
        "The partner field is required when married is 1.": "The @:(label.partner) field is required when married is 1.",
        "The image must be a valid json string.": "The @:(label.image) must be a valid json string.",
        "The wedding_date does not match the format Y-m-dtHis000z.": "The @:(label.wedding_date) does not match the format Y-m-dtHis000z.",
        "Member created.": "Member created.",
        "The baptized field must be true or false.": "The @:(label.baptized) field must be true or false.",
        "The married field must be true or false.": "The @:(label.married) field must be true or false.",
        "The sex field must be true or false.": "The @:(label.sex) field must be true or false.",
        "The image may not be greater than 2048 kilobytes.": "The @:(label.image) may not be greater than 2048 kilobytes.",
        "The image must be a file.": "The @:(label.image) must be a file.",
        "The image must be a file of type: jpeg, bmp, png.": "The @:(label.image) must be a file of type: jpeg, bmp, png.",
        "The image must be an image.": "The @:(label.image) must be an image.",
        "The password must be at least 6 characters.": "The @:(label.password) must be at least 6 characters.",
        "The name may not be greater than 1 characters.": "The @:(label.name) may not be greater than 1 characters.",
        "The email may not be greater than 1 characters.": "The @:(label.email) may not be greater than 1 characters.",
    },
    messages: {
        signin: "Log in to your account",
        forgot_pass: "Forgot your password?",
        selected_image: "Selected image",
        choose_file: "Choose a file",
        please: "Please",
        enter_name: "@:(messages.please), enter the name",
        enter_mobile: "@:(messages.please), enter the cell",
        you_sure: "Are you sure?",
        deleting_alert: "Once deleted, you will not be able to recover that item!",
        error_alert: "Oh no!",
        delete_request_failed: "The deletion request failed!"
    },
    label: {
        username: "Username",
        password: "Password",
        login: "Login",
        add: "Add",
        member: "Member | Members",
        name: "Name",
        sex: "Sex",
        sexes: "@:(label.male) | @:(label.female)",
        male: "Male",
        female: "Female",
        baptized: "Baptized",
        married: "Married",
        birthday: "Birthday",
        wedding_date: "Wedding date",
        address: "Address",
        city: "City",
        cep: "Zip Code",
        phone: "Phone",
        mobile: "Mobile",
        email: "E-mail",
        father: "Father",
        mother: "Mother",
        ministry: "Ministry",
        role: "Role | Roles",
        submit: "Submit",
        notification: "Notification",
        continue: "Continue",
        updated_at: "Updated At",
        actions: "Actions",
        edit: "Edit",
        account: "Account",
        profile: "Profile",
        settings: "Settings",
        logout: "Exit",
        image: "Image",
        prev: "Previous",
        next: "Next",
        more: "more",
        department: "Department | Departments",
        partner: "Spouse",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
        warning: "Warning",
        YYYYMMDD: "YYYY-MM-DD",
        back: "Back",
        home: "Home",
        dashboard: "Dashboard",
        save: "Save",
        resume: "Resume",
        portfolio: "Portfolio",
        contact: "Contact",
        lab: "Lab",
        message: "Message",
        skills_rank: "I don't know | Novice | Advanced Beginner | Competent | Proficient | Expert",
        specialties: "Specialties",
        "frameworks/cms": "Frameworks/CMS",
        "front-end/ux/ui": "Front-end/UX/UI",
        "databases/dba": "@:(label.databases)",
        "back-end/tools": "Back-end/Tools",
        databases: "Databases",
        others: "Others",
        education: "Education",
        graduation: "Graduation",
        customers: "Customers",
        projects: "Projects",
        programming_languages: "Programming languages",
        operating_systems: "Operating Systems",
        ide_browser: "IDE/Browser",
        time_spent_on: "Time spent on",
        time_spent_description: "Since mid-2015",
        technologies: "Technologies",
        certifications: "Certifications",
        about_me: "About me",
        frameworks: "Frameworks",
        calendar: "Calendar",
        busy: "Busy",
        day: "Day",
        week: "Week",
        month: "Month",
        today: "Today",
    },
}