export default {
    api: {
        "Exception": "Houve uma falha na requisição, tente novamente mais tarde.",
        "Request is failed!": "Falha no envio!",
        "Authentication is required!": "E necessário se autenticar!",
        "Resource not found!": "Recurso não encontrado!",
        "Unauthorized": "Não autorizado",
        "Invalid credentials": "Credenciais fornecidas estão incorretas ou não existem",
        "Successfully updated.": "Atualizado com sucesso",
        "Successfully created.": "Criado com sucesso",
        "Successfully deleted!": "Excluído com sucesso",
        "The ministry field is required.": "O campo @:(label.department) é obrigatório",
        "The role field is required.": "O campo @:(label.role) é obrigatório",
        "The sex field is required.": "O campo @:(label.sex) é obrigatório",
        "The name field is required.": "O campo @:(label.name) é obrigatório",
        "The email field is required.": "O campo @:(label.email) é obrigatório",
        "The address field is required.": "O campo @:(label.address) é obrigatório",
        "The baptized field is required.": "O campo @:(label.baptized) é obrigatório",
        "The birthday field is required.": "O campo @:(label.birthday) é obrigatório",
        "The cep field is required.": "O campo @:(label.cep) é obrigatório",
        "The city field is required.": "O campo @:(label.city) é obrigatório",
        "The image field is required.": "O campo @:(label.image) é obrigatório",
        "The married field is required.": "O campo @:(label.married) é obrigatório",
        "The mobile field is required.": "O campo @:(label.mobile) é obrigatório",
        "The phone field is required.": "O campo @:(label.phone) é obrigatório",
        "The wedding_date field is required.": "O campo @:(label.wedding_date) é obrigatório",
        "The email has already been taken.": "O @:(label.email) informado já está em uso!",
        "The wedding_date is not a valid date.": "O campo @:(label.wedding_date) não é uma data válida!",
        "The birthday is not a valid date.": "O campo @:(label.birthday) não é uma data válida!",
        "The partner field is required when married is 1.": "O campo @:(label.partner) é obrigatório, quando o campo @:(label.married) está marcado",
        "The image must be a valid json string.": "O campo @:(label.image) está incorreto!",
        "The wedding_date does not match the format Y-m-dtHis000z.": "@:(api.theweddingdateisnotavaliddate)",
        "Member created.": "@.single:(label.member) cadastrado com sucesso!",
        "The baptized field must be true or false.": "O campo @:(label.baptized) precisa ser verdadeiro ou falso",
        "The married field must be true or false.": "O campo @:(label.married) precisa ser verdadeiro ou falso",
        "The sex field must be true or false.": "O campo @:(label.sex) precisa ser verdadeiro ou falso",
        "The image may not be greater than 2048 kilobytes.": "O campo @:(label.image) não deve ter mais que 2048 kilobytes",
        "The image must be a file.": "O campo @:(label.image) deve ser um arquivo",
        "The image must be a file of type: jpeg, bmp, png.": "O campo @:(label.image) deve ser um arquivo do tipo: jpeg, bmp, png",
        "The image must be an image.": "O campo @:(label.image) deve ser uma imagem",
        "The password must be at least 6 characters.": "O campo @:(label.password) deve ter no mínimo 6 caracteres",
        "The name may not be greater than 1 characters.": "O campo @:(label.name) não ser maior do que 1 caracter",
        "The email may not be greater than 1 characters.": "O campo @:(label.email) não ser maior do que 1 caracter",
    },
    messages: {
        signin: "Faça login em sua conta",
        forgot_pass: "Esqueceu sua senha?",
        selected_image: "Imagem selecionada",
        choose_file: "Escolher um arquivo",
        please: "Por favor",
        enter_name: "@:(messages.please), informe o nome",
        enter_mobile: "@:(messages.please), informe o celular",
        you_sure: "Você tem certeza?",
        deleting_alert: "Uma vez Excluído, você não poderá recuperar esse item!",
        error_alert: "Oh não!",
        delete_request_failed: "A solicitação de exclusão falhou!"
    },
    label: {
        username: "Nome de usuário",
        password: "Senha",
        login: "Login",
        add: "Adicionar",
        member: "Membro | Membros",
        name: "Nome",
        sex: "Sexo",
        sexes: "@:(label.male) | @:(label.female)",
        male: "Masculino",
        female: "Feminino",
        baptized: "Batizado",
        married: "Casado",
        birthday: "Nascimento",
        wedding_date: "Data de casamento",
        address: "Endereço",
        city: "Cidade",
        cep: "CEP",
        phone: "Telefone",
        mobile: "Celular",
        email: "E-mail",
        father: "Pai",
        mother: "Mãe",
        ministry: "Ministério",
        role: "Função | Funções",
        submit: "Enviar",
        notification: "Notificação",
        continue: "Continuar",
        updated_at: "Atualizado em",
        actions: "Ações",
        edit: "Editar",
        account: "Conta",
        profile: "Perfil",
        settings: "Configurações",
        logout: "Sair",
        image: "Imagem",
        prev: "Anterior",
        next: "Próxima",
        more: "mais",
        department: "Departamento | Departamentos",
        partner: "Cônjuge",
        yes: "Sim",
        no: "Não",
        ok: "Ok",
        cancel: "Cancelar",
        warning: "Atenção",
        YYYYMMDD: "AAAA-MM-DD",
        back: "Voltar",
        home: "Início",
        dashboard: "Dashboard",
        save: "Salvar",
        resume: "Curriculo",
        portfolio: "Portfólio",
        contact: "Contato",
        lab: "Lab",
        message: "Mensagem",
        skills_rank: "I don't know | Novato | Iniciante Avançado | Competente | Proficiente | Especialista",
        specialties: "Especialidades",
        "frameworks/cms": "Frameworks/CMS",
        "front-end/ux/ui": "Front-end/UX/UI",
        "databases/dba": "@:(label.databases)",
        "back-end/tools": "Back-end/Tools",
        databases: "Banco de Dados",
        others: "Outros",
        education: "Educação",
        graduation: "Graduação",
        customers: "Clientes",
        projects: "Projetos",
        programming_languages: "Linguagens de Programação",
        operating_systems: "Sistemas Operacionais",
        ide_browser: "IDE/Browser",
        time_spent_on: "Tempo usado em",
        time_spent_description: "Desde meados de 2015",
        technologies: "Tecnologias",
        certifications: "Certificações",
        about_me: "Sobre mim",
        frameworks: "Frameworks",
        calendar: "Agenda",
        busy: "Ocupado",
        day: "Dia",
        week: "Semana",
        month: "Mês",
        today: "Hoje",
    },
}