import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: ''
};

export const getters = {
    loadStatus: state => state.status
};

export const mutations = {
    /** STORE */
    [types.STORE_CONTACT_ERROR](state) {
        state.status = 'error';
    },
    [types.STORE_CONTACT_SUCCESS](state, {data}) {
        state.status = 'success';
        state.contact = data
    },
    [types.STORE_CONTACT_LOADING](state) {
        state.status = 'loading';
    },
};

export const actions = {
    store({commit}, params) {
        return new Promise((resolve, reject) => {
            commit(types.STORE_CONTACT_LOADING);
            http.post('contact', params).then(({data}) => {
                commit(types.STORE_CONTACT_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.STORE_CONTACT_ERROR)
                reject(response.data)
            })
        })
    },
};
