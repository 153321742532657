import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: '',
    version: null,
};

export const getters = {
    loadStatus: state => state.status,
    version: state => state.version,
};

export const mutations = {
    /** LOAD */
    [types.LOAD_VERSION_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_VERSION_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.version = data
    },
    [types.LOAD_VERSION_ERROR](state) {
        state.status = 'error'
    },
};

export const actions = {
    load({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_VERSION_LOADING);
            http.get('version').then(({data}) => {
                commit(types.LOAD_VERSION_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_VERSION_ERROR)
                reject(response.data)
            })
        })
    }
};
