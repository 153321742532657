import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import {extend, configure, setInteractionMode} from 'vee-validate';
import * as GmapVue from 'gmap-vue';

/** Styles **/
import '~/scss/main.scss';

/** Translations */
import translation_pt from './i18n/pt';
import translation_en from './i18n/en';
import {pt as vuetifyMessages_pt, en as vuetifyMessages_en} from 'vuetify/lib/locale';
import {messages as veeMessages_pt} from 'vee-validate/dist/locale/pt_BR.json';
import {messages as veeMessages_en} from 'vee-validate/dist/locale/en.json';

/** Validation rules to VeeValidate */
import * as rules from 'vee-validate/dist/rules';

Vue.use(Vuetify, {options: {customProperties: true}});
Vue.use(VueI18n);
Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY
    }
});

setInteractionMode('eager')

/** Merge all messages from Vuetify, VeeValidate, local */
const messages_pt = Object.assign({}, translation_pt, {
    $vuetify: vuetifyMessages_pt,
    fields: translation_pt.label,
    validation: veeMessages_pt
});
const messages_en = Object.assign({}, translation_en, {
    $vuetify: vuetifyMessages_en,
    fields: translation_en.label,
    validation: veeMessages_en
});
/** Set messages on VueI18n */
const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        pt: messages_pt,
        en: messages_en,
    },
    modifiers: {
        single: (str) => i18n.tc(str),
        snakeCase: (str) => str.split(' ').join('-')
    },

    // Key - language to use the rule for, `'ru'`, in this case
    // Value - function to choose right plural form
    pluralizationRules: {
        /**
         * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} an overall amount of available choices
         * @returns a final choice index to select plural word by
         */
        'pt': choice => choice,
        'en': choice => choice,
    }
});

/**
 * Configure VeeValidate to translate field names and validation messages
 * Overrides the field name.
 * @NOTE: Only works when the "extend" function don't set the "message" option
 */
configure({
    defaultMessage: (field, values) => {
        values._field_ = i18n.t(`fields.${field}`).toLowerCase();
        return i18n.t(`validation.${values._rule_}`, values);
    }
});

/**
 * Set all messages to default VeeValidate rules
 * @NOTE: don't use "extend+message" while using "configure" above. ex: "message: veeMessages[rule]"
 */
Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule],
        //message: some string
    });
});

/** Configure Vuetify to use VueI18n */
export default new Vuetify({
    lang: {
        locales: {messages_en},
        current: 'en',
        t: (key, ...params) => i18n.t(key, params),
    },
});

export {i18n};