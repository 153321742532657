import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, Axios);

// Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
// Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

let axiosInstance = Axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
);

export default axiosInstance;
