const DefaultLayout = () => import('~/views/layouts/Default')

const Resume = () => import('~/views/resume/Index')
const ResumeAside = () => import('~/views/resume/Aside')

const Portfolio = () => import('~/views/portfolio/Index')
const PortfolioAside = () => import('~/views/portfolio/Aside')

const Contact = () => import('~/views/contact/Index')
const ContactAside = () => import('~/views/contact/Aside')

const Calendar = () => import('~/views/calendar/Index')
const CalendarAside = () => import('~/views/calendar/Aside')

const Lab = () => import('~/views/lab/Index')
const LabAside = () => import('~/views/lab/Aside')

/**
 * Path               View                      Name            Component Alias       Description
 * examples           examples/Index.vue        examples        Examples              display a list/table of all examples | the main "example" page
 * examples/create    examples/CreateEdit.vue   example.create  ExampleCreateEdit     return an HTML form for creating a new example | a create page for the "example"
 * examples/:id/edit  examples/CreateEdit.vue   example.edit    ExampleCreateEdit     return an HTML form for editing a example | an edit page for the "example"
 */
export default [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'label.home',
            requiresAuth: false
        },
        redirect: 'resume',
        component: DefaultLayout,
        children: [
            {
                path: 'resume',
                meta: {
                    title: 'label.resume',
                    showInMenu: true,
                    showInBreadcrumb: true,
                    requiresAuth: true,
                    icon: 'mdi-card-account-details'
                },
                components: {
                    default: Resume,
                    aside: ResumeAside
                }
            },
            {
                path: 'portfolio',
                meta: {
                    title: 'label.portfolio',
                    showInMenu: true,
                    showInBreadcrumb: true,
                    requiresAuth: true,
                    icon: 'mdi-briefcase'
                },
                components: {
                    default: Portfolio,
                    aside: PortfolioAside
                }
            },
            {
                path: 'calendar',
                meta: {
                    title: 'label.calendar',
                    showInMenu: true,
                    showInBreadcrumb: true,
                    requiresAuth: true,
                    icon: 'mdi-calendar-month'
                },
                components: {
                    default: Calendar,
                    aside: CalendarAside
                }
            },
            {
                path: 'lab',
                meta: {
                    title: 'label.lab',
                    showInMenu: true,
                    showInBreadcrumb: true,
                    requiresAuth: true,
                    icon: 'mdi-test-tube'
                },
                components: {
                    default: Lab,
                    aside: LabAside
                }
            },
            {
                path: 'contact',
                meta: {
                    title: 'label.contact',
                    showInMenu: true,
                    showInBreadcrumb: true,
                    requiresAuth: true,
                    icon: 'mdi-email'
                },
                components: {
                    default: Contact,
                    aside: ContactAside
                }
            },
        ]
    }
]