import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: '',
    time: null,
    search: null,
};

export const getters = {
    loadStatus: state => state.status,
    time: state => state.time,
    search: state => state.search
};

export const mutations = {
    /** LOAD */
    [types.LOAD_TIME_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_TIME_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.time = data
    },
    [types.LOAD_TIME_ERROR](state) {
        state.status = 'error'
    },
    /** SEARCH */
    [types.SEARCH_TIME_LOADING](state) {
        state.status = 'searching'
    },
    [types.SEARCH_TIME_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.search = data
    },
    [types.SEARCH_TIME_ERROR](state) {
        state.status = 'error'
    },
};

export const actions = {
    load({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_TIME_LOADING);
            http.get('time-report').then(({data}) => {
                commit(types.LOAD_TIME_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_TIME_ERROR)
                reject(response.data)
            })
        })
    },
    search({commit}, params) {
        return new Promise((resolve, reject) => {
            commit(types.SEARCH_TIME_LOADING);
            http.get('time-reports/grouped-technology', {params}).then(({data}) => {
                commit(types.SEARCH_TIME_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.SEARCH_TIME_ERROR)
                reject(response.data)
            })
        })
    }
};
