import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: '',
    tags: null,
    cloud: null,
};

export const getters = {
    loadStatus: state => state.status,
    tags: state => state.tags,
    cloud: state => state.cloud
};

export const mutations = {
    /** LOAD */
    [types.LOAD_TAGS_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_TAGS_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.tags = data
    },
    [types.LOAD_TAGS_ERROR](state) {
        state.status = 'error'
    },
    /** CLOUD */
    [types.LOAD_TAGS_CLOUD_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_TAGS_CLOUD_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.cloud = data
    },
    [types.LOAD_TAGS_CLOUD_ERROR](state) {
        state.status = 'error'
    }
};

export const actions = {
    load({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_TAGS_LOADING);
            http.get('tags').then(({data}) => {
                commit(types.LOAD_TAGS_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_TAGS_ERROR)
                reject(response.data)
            })
        })
    },
    cloud({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_TAGS_CLOUD_LOADING);
            http.get('tags/cloud').then(({data}) => {
                commit(types.LOAD_TAGS_CLOUD_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_TAGS_CLOUD_ERROR)
                reject(response.data)
            })
        })
    }
};
