import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: '',
    experiences: null,
};

export const getters = {
    loadStatus: state => state.status,
    experiences: state => state.experiences
};

export const mutations = {
    /** LOAD */
    [types.LOAD_EXPERIENCES_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_EXPERIENCES_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.experiences = data
    },
    [types.LOAD_EXPERIENCES_ERROR](state) {
        state.status = 'error'
    }
};

export const actions = {
    load({commit}) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_EXPERIENCES_LOADING);
            http.get('experiences/complete').then(({data}) => {
                commit(types.LOAD_EXPERIENCES_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_EXPERIENCES_ERROR)
                reject(response.data)
            })
        })
    }
};
