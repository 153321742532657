/**
 * Naming convention:
 * - load
 * - store
 * - list
 * - show
 * - update
 * - delete
 * Example:
 * LOAD_POSTS_REQUEST: 'LOAD_POSTS_REQUEST',
 * LOAD_POSTS_FAILURE: 'LOAD_POSTS_FAILURE',
 * LOAD_POSTS_SUCCESS: 'LOAD_POSTS_SUCCESS',
 */

// experiences.js
export const LOAD_EXPERIENCES_LOADING = 'LOAD_EXPERIENCES_LOADING';
export const LOAD_EXPERIENCES_SUCCESS = 'LOAD_EXPERIENCES_SUCCESS';
export const LOAD_EXPERIENCES_ERROR = 'LOAD_EXPERIENCES_ERROR';

// certifications.js
export const LOAD_CERTIFICATIONS_LOADING = 'LOAD_CERTIFICATIONS_LOADING';
export const LOAD_CERTIFICATIONS_SUCCESS = 'LOAD_CERTIFICATIONS_SUCCESS';
export const LOAD_CERTIFICATIONS_ERROR = 'LOAD_CERTIFICATIONS_ERROR';

// graduations.js
export const LOAD_GRADUATIONS_LOADING = 'LOAD_GRADUATIONS_LOADING';
export const LOAD_GRADUATIONS_SUCCESS = 'LOAD_GRADUATIONS_SUCCESS';
export const LOAD_GRADUATIONS_ERROR = 'LOAD_GRADUATIONS_ERROR';

// details.js
export const LOAD_DETAILS_LOADING = 'LOAD_DETAILS_LOADING';
export const LOAD_DETAILS_SUCCESS = 'LOAD_DETAILS_SUCCESS';
export const LOAD_DETAILS_ERROR = 'LOAD_DETAILS_ERROR';
export const LIST_DETAILS_LOADING = 'LIST_DETAILS_LOADING';
export const LIST_DETAILS_SUCCESS = 'LIST_DETAILS_SUCCESS';
export const LIST_DETAILS_ERROR = 'LIST_DETAILS_ERROR';

// projects.js
export const LOAD_PROJECTS_LOADING = 'LOAD_PROJECTS_LOADING';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_ERROR = 'LOAD_PROJECTS_ERROR';

// skills.js
export const LOAD_SKILLS_LOADING = 'LOAD_SKILLS_LOADING';
export const LOAD_SKILLS_SUCCESS = 'LOAD_SKILLS_SUCCESS';
export const LOAD_SKILLS_ERROR = 'LOAD_SKILLS_ERROR';
export const LIST_SKILLS_LOADING = 'LIST_SKILLS_LOADING';
export const LIST_SKILLS_SUCCESS = 'LIST_SKILLS_SUCCESS';
export const LIST_SKILLS_ERROR = 'LIST_SKILLS_ERROR';

// tags.js
export const LOAD_TAGS_LOADING = 'LOAD_TAGS_LOADING';
export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS';
export const LOAD_TAGS_ERROR = 'LOAD_TAGS_ERROR';
export const LOAD_TAGS_CLOUD_LOADING = 'LOAD_TAGS_CLOUD_LOADING';
export const LOAD_TAGS_CLOUD_SUCCESS = 'LOAD_TAGS_CLOUD_SUCCESS';
export const LOAD_TAGS_CLOUD_ERROR = 'LOAD_TAGS_CLOUD_ERROR';

// contact.js
export const STORE_CONTACT_LOADING = 'STORE_CONTACT_LOADING';
export const STORE_CONTACT_SUCCESS = 'STORE_CONTACT_SUCCESS';
export const STORE_CONTACT_ERROR = 'STORE_CONTACT_ERROR';

// time.js
export const LOAD_TIME_LOADING = 'LOAD_TIME_LOADING';
export const LOAD_TIME_SUCCESS = 'LOAD_TIME_SUCCESS';
export const LOAD_TIME_ERROR = 'LOAD_TIME_ERROR';
export const SEARCH_TIME_LOADING = 'SEARCH_TIME_LOADING';
export const SEARCH_TIME_SUCCESS = 'SEARCH_TIME_SUCCESS';
export const SEARCH_TIME_ERROR = 'SEARCH_TIME_ERROR';

// version.js
export const LOAD_VERSION_LOADING = 'LOAD_VERSION_LOADING';
export const LOAD_VERSION_SUCCESS = 'LOAD_VERSION_SUCCESS';
export const LOAD_VERSION_ERROR = 'LOAD_VERSION_ERROR';

// events.js
export const LOAD_EVENTS_LOADING = 'LOAD_EVENTS_LOADING';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_ERROR = 'LOAD_EVENTS_ERROR';