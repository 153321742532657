<template>
  <v-app id="inspire">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route(to, from) {
      document.title = to.meta.title ? `${this.$tc(to.meta.title, 2)} :: ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE;
    },
  }
};
</script>