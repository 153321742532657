import http from '../http'
import * as types from '../mutation-types';

export const state = {
    status: '',
    events: null,
};

export const getters = {
    loadStatus: state => state.status,
    events: state => state.events
};

export const mutations = {
    /** LOAD */
    [types.LOAD_EVENTS_LOADING](state) {
        state.status = 'loading'
    },
    [types.LOAD_EVENTS_SUCCESS](state, {data, meta}) {
        state.status = 'success'
        state.events = data
    },
    [types.LOAD_EVENTS_ERROR](state) {
        state.status = 'error'
    }
};

export const actions = {
    load({commit}, params) {
        return new Promise((resolve, reject) => {
            commit(types.LOAD_EVENTS_LOADING);
            http.get('events/complete', {params: params}).then(({data}) => {
                commit(types.LOAD_EVENTS_SUCCESS, data)
                resolve(data)
            }).catch(({response}) => {
                commit(types.LOAD_EVENTS_ERROR)
                reject(response.data)
            })
        })
    }
};
